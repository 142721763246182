import { createAsyncThunk, createAction } from '@reduxjs/toolkit';

import { IRootState } from '../../../frameworks/redux';
import { customReportSelectors } from './custom-report.selectors';
import { Logger } from '@repo/shared/services';
import { removeUndefinedAndNullProps } from '@utils';
import { hasOwnProperty } from '@repo/shared/utils';
import {
  getErrorMessage,
  getExportFileMetaData,
  saveFile,
} from '@repo/shared/utils';
import { IPagingMeta, ISharedFilters } from '@repo/shared/types';
import {
  ICustomReportFilters,
  IGetCustomReportResponse,
} from '@repo/shared/types';
import { DataCategoryType, ExportType } from '@repo/shared/enums';
import { apiUrls } from '@config';
import { generalSelectors } from '@store';
import { InternalApiService } from '@repo/shared/api';

const apiService = InternalApiService.getInstance();

function constructApiUrl(
  dataCategoryType: DataCategoryType,
  exportFormat?: ExportType
) {
  let url = apiUrls.reports.custom;

  const dataCategoriesUrlParts = {
    [DataCategoryType.Audits]: 'audit',
    [DataCategoryType.CorrectiveActions]: 'corrective-actions',
    [DataCategoryType.AuditItems]: 'audit-items',
  };

  url += `/${dataCategoriesUrlParts[dataCategoryType]}`;

  if (exportFormat !== undefined) {
    const exportFormatUrlParts = {
      [ExportType.Csv]: 'csv',
      [ExportType.Pdf]: 'pdf',
      [ExportType.Xls]: 'xls',
    };

    url += `/export/${exportFormatUrlParts[exportFormat]}`;
  }

  return url;
}

export const customReportActions = {
  getCustomReport: createAsyncThunk<
    IGetCustomReportResponse,
    | Partial<
        ICustomReportFilters & Omit<IPagingMeta, 'totalCount'> & ISharedFilters
      >
    | undefined,
    { rejectValue: string; state: IRootState }
  >('reports/custom/get', async (update, { getState }) => {
    const state = getState();

    const customReportFilters = customReportSelectors.getFilters(state);
    const sharedFilters = generalSelectors.getSharedFilters(state);

    const body = {
      ...sharedFilters,
      ...customReportFilters,
      ...(update || {}),
    };

    if (body.templateId) {
      delete body.templateId;
    }

    if (update && hasOwnProperty(update, 'dataCategory')) {
      body.selectedColumns = [];
    }

    return apiService.post({
      url: constructApiUrl(customReportFilters.dataCategory),
      body,
    });
  }),
  toggleExportModal: createAction<ExportType | null>(
    'reports/custom/toggleExportModal'
  ),
  exportReport: createAsyncThunk<void, string, { state: IRootState }>(
    'reports/custom/exportReport',
    async (reportName, { getState, rejectWithValue }) => {
      const state = getState();
      const { filters, exportModalType } = state.reports.custom;
      const sharedFilters = generalSelectors.getSharedFilters(state);

      if (exportModalType === null) {
        return;
      }

      try {
        const { data } = await apiService.post<{ data: 'arraybuffer' }>({
          responseType: 'arraybuffer',
          url: constructApiUrl(filters.dataCategory, exportModalType),
          body: removeUndefinedAndNullProps({
            ...filters,
            ...sharedFilters,
          }),
          withHeaders: true,
        });

        const { extension, mimeType } = getExportFileMetaData(exportModalType);

        saveFile({
          data,
          mimeType,
          fileName: `${reportName}.${extension}`,
        });
      } catch (e) {
        Logger.captureException(e);
        return rejectWithValue(getErrorMessage(e));
      }
    }
  ),
};
