import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { IAnyObject, IColumnDataType } from '@repo/shared/types';
import { accountSelectors } from '@store';
import {
  ActionsColumns,
  AuditColumns,
  AuditItemsColumns,
  DataCategoryType,
} from '@repo/shared/enums';

const dataTypeToColumnsLangIdsMap = {
  [DataCategoryType.Audits]: {
    [AuditColumns.AssignedTo]: 'AssignedTo',
    [AuditColumns.CompletedActions]: 'CompletedActions',
    [AuditColumns.CompletedDate]: 'CompletedDate',
    [AuditColumns.CompletedBy]: 'CompletedBy',
    [AuditColumns.CreatedDate]: 'CreatedDate',
    [AuditColumns.Name]: 'Name',
    [AuditColumns.DueDate]: 'DueDate',
    [AuditColumns.FailedItemCount]: 'FailedItemCount',
    [AuditColumns.Object]: 'AuditObject',
    [AuditColumns.PendingActions]: 'PendingActions',
    [AuditColumns.PastDue]: 'PastDue',
    [AuditColumns.Score]: 'Score',
    [AuditColumns.ScoreLabel]: 'ScoreLabel',
    [AuditColumns.Status]: 'Status',
    [AuditColumns.StartedDate]: 'StartDate',
    [AuditColumns.Number]: 'Number',
    [AuditColumns.TemplateName]: 'TemplateName',
    [AuditColumns.AuditObjectGroup]: 'AuditObjectGroup',
  },
  [DataCategoryType.CorrectiveActions]: {
    [ActionsColumns.Name]: 'Name',
    [ActionsColumns.AuditName]: 'AuditName',
    [ActionsColumns.AssignedTo]: 'AssignedTo',
    [ActionsColumns.CompletedDate]: 'CompletedDate',
    [ActionsColumns.CreatedDate]: 'CreatedDate',
    [ActionsColumns.CreatedBy]: 'CreatedBy',
    [ActionsColumns.Description]: 'Description',
    [ActionsColumns.LastActivityDate]: 'LastActivityDate',
    [ActionsColumns.OriginalDueDate]: 'OriginalDueDate',
    [ActionsColumns.CurrentDueDate]: 'CurrentDueDate',
    [ActionsColumns.PastDue]: 'PastDue',
    [ActionsColumns.Priority]: 'Priority',
    [ActionsColumns.Question]: 'Question',
    [ActionsColumns.Status]: 'Status',
    [ActionsColumns.Critical]: 'Critical',
    [ActionsColumns.Number]: 'Number',
    [ActionsColumns.AuditObject]: 'AuditObject',
    [ActionsColumns.AuditObjectGroup]: 'AuditObjectGroup',
    [ActionsColumns.ApprovedBy]: 'ApprovedBy',
    [ActionsColumns.TemplateName]: 'TemplateName',
    [ActionsColumns.Tags]: 'Tags',
  },
  [DataCategoryType.AuditItems]: {
    [AuditItemsColumns.Number]: 'Number',
    [AuditItemsColumns.Question]: 'Question',
    [AuditItemsColumns.QuestionResponse]: 'QuestionResponse',
    [AuditItemsColumns.Comment]: 'Comment',
    [AuditItemsColumns.Flags]: 'Flags',
    [AuditItemsColumns.RepeatFailureCount]: 'RepeatFailureCount',
    [AuditItemsColumns.HasCorrectiveActions]: 'HasCorrectiveActions',
    [AuditItemsColumns.Audit]: 'Audit',
    [AuditItemsColumns.AuditDate]: 'AuditDate',
    [AuditItemsColumns.AuditCompletedBy]: 'AuditCompletedBy',
    [AuditItemsColumns.PointsEarned]: 'PointsEarned',
    [AuditItemsColumns.AuditObject]: 'AuditObject',
    [AuditItemsColumns.AuditObjectGroup]: 'AuditObjectGroup',
    [AuditItemsColumns.IsCritical]: 'Critical',
    [AuditItemsColumns.Section]: 'Section',
    [AuditItemsColumns.Subsection]: 'SubSection',
    [AuditItemsColumns.IsFailed]: 'Failed',
    [AuditItemsColumns.QuestionValue]: 'QuestionValue',
    [AuditItemsColumns.IsNotApplicable]: 'N/A',
  },
};

export function useCustomReportColumnNames(
  dataCategory: DataCategoryType
): Map<IColumnDataType, string> {
  const { formatMessage } = useIntl();
  const objectName = useSelector(accountSelectors.getObjectName);

  return useMemo(() => {
    const columnDataTypeToLangIdMap = new Map();

    Object.entries(dataTypeToColumnsLangIdsMap[dataCategory]).forEach(
      ([columnDataTypeStr, langId]) => {
        const columnDataType = parseInt(columnDataTypeStr);

        let columnName = formatMessage(
          { id: langId },
          { objectName: objectName.single }
        );

        if (
          (dataCategory === DataCategoryType.Audits &&
            columnDataType === AuditColumns.Object) ||
          (dataCategory === DataCategoryType.CorrectiveActions &&
            columnDataType === ActionsColumns.AuditObject) ||
          (dataCategory === DataCategoryType.AuditItems &&
            columnDataType === AuditItemsColumns.AuditObject)
        ) {
          columnName = objectName.single;
        }

        return columnDataTypeToLangIdMap.set(columnDataType, columnName);
      }
    );

    return columnDataTypeToLangIdMap;
  }, [dataCategory]);
}

export function useIsEmptyChartData(data: IAnyObject[] | null) {
  return useMemo(() => {
    if (!Array.isArray(data) || data.length === 0) {
      return true;
    }

    return data.every((item) => item.count === 0);
  }, [data]);
}
